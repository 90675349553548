<template>
    <div class="b2c-page-bg">
        <div class="b2c-pc-card-header">
            <a href="/itshop/tat-ca">
                <img src="../../../assets/images/prev-arrow.png" alt="" />
            </a>
            <span>MỞ THẺ TÍN DỤNG VPBANK</span>
        </div>
        <div class="b2c-pc-card-content">
            <div class="row d-flex justify-content-center b2c-pc-card-content-row">
                <div class="col-md-8 col-sm-12 ">
                    <div class="row h-100 d-flex justify-content-center">
                        <div
                            :class="step === 1 ? `col-md-6 col-sm-12 credit-card-container` : 'col-md-6 col-sm-12 credit-card-container pb-sp-040'">
                            <div class="d-flex justify-content-center open-credit-title">
                                <img src="../../../assets/images/open-credit-card-title.png" />
                            </div>
                            <div class="d-flex justify-content-center open-credit-button" @click="openVPBank()">
                                <img src="../../../assets/images/open-credit-card-button.png" />
                            </div>
                            <div class="list-vpbank-info">
                                <ul>
                                    <li>Mở thẻ tín dụng siêu nhanh, thuận tiện</li>
                                    <li>Chỉ 10 phút đăng ký, 100% online</li>
                                    <li>Không cần đến quầy giao dịch</li>
                                    <li>Phê duyệt tự động siêu tốc trong 24h</li>
                                    <li>Chỉ cần duy nhất CMND/CCCD/Hộ chiếu</li>
                                    <li>Miễn phí rút tiền, Miễn phí thường niên (*)</li>
                                    <li>Tích điểm đổi quà, Hoàn tiền lên đến 15%</li>
                                </ul>
                            </div>
                            <div class="open-credit-apply-text">
                                (*)Áp dụng cho tùy từng loại thẻ
                            </div>
                        </div>
                        <div
                            :class="step === 1 ? 'col-md-5 col-sm-12 h-100 p-0' : 'col-md-5 col-sm-12 h-100 p-0 col-step-1'">
                            <div class="open-credit-form">
                                <div class="open-credit-form-title">
                                    MỞ THẺ VPBANK NGAY RINH 200K VỀ TAY
                                </div>
                                <hr class="open-credit-form-hr" />
                                <div class="open-credit-privacy" v-if="step === 1">
                                    <p>
                                        Từ 20/2/2023 đến hết 30/06/2023, khi Bạn mở mới thẻ tín dụng VPBank trên website itel.vn hoặc trên app My iTel, kích hoạt thẻ thành công sẽ nhận ngay 200.000 đồng, cụ thể:
                                    </p>
                                    <ul>
                                        <li>
                                            Nhận ngay 200.000 đồng vào tài khoản điện thoại khi mở mới thẻ tín dụng VPBank trên website itel.vn hoặc trên app My iTel & kích hoạt thẻ thành công trong thời gian diễn ra chương trình.
                                        </li>
                                        <li>
                                            Số điện thoại nhận thưởng là số điện thoại Bạn sử dụng để đăng ký mở thẻ thành công.
                                        </li>
                                        <li>
                                            Bạn sẽ nhận được ưu đãi tối đa 02 lần trong thời gian diễn ra chương trình.
                                        </li>
                                        <li>
                                            Chương trình chỉ áp dụng khi Bạn là Khách hàng cá nhân.
                                        </li>
                                        <li>
                                            Áp dụng đồng thời với các chương trình khuyến mại mở thẻ mới khác được VPBank triển khai trong cùng thời kỳ.
                                        </li>
                                    </ul>
                                    <h4 style="color: red; font-weight: bold;">
                                        A. Các bước đăng ký mở thẻ :
                                    </h4>
                                    <ul>
                                        <li>
                                            <span style="font-style: italic;">Bước 01:</span> Đăng nhập trên app My iTel hoặc truy cập website itel.vn
                                        </li>
                                        <li>
                                            <span style="font-style: italic;">Bước 02:</span> Vào ITEL SHOP và chọn mục “Tài chính – Bảo hiểm”, chọn “Mở thẻ tín dụng VPBank”
                                        </li>
                                        <li>
                                            <span style="font-style: italic;">Bước 03:</span>Xem nội dụng thông tin về chương trình
                                        </li>
                                        <li>
                                            <span style="font-style: italic;">Bước 04:</span>Thực hiện nhập thông tin theo hướng dẫn để hoàn tất thủ tục đăng ký mở thẻ
                                        </li>
                                    </ul>
                                    <h4>
                                       <span style="color: red; font-weight: bold;"> B. Các bước nhận Ưu đãi: </span>Để nhận Ưu đãi 200.000 đồng, Bạn vui lòng chú ý và thực hiện theo hướng dẫn sau
                                    </h4>
                                    <ul>
                                        <li>
                                            <span style="font-style: italic;">Bước 01:</span> Hoàn thành kích hoạt thẻ theo hướng dẫn từ VPBank
                                        </li>
                                        <li>
                                            <span style="font-style: italic;">Bước 02:</span> Sau 5-10 ngày, kiểm tra email được gửi từ VPBank với tiêu đề "Thư cảm ơn Khách hàng đã tham gia chương trình mở thẻ Tín dụng theo chương trình liên kết đối tác VPBank -iTel".
                                        </li>
                                        <li>
                                            <span style="font-style: italic;">Bước 03:</span> Truy cập link trong email, nhập thông tin để nhận thưởng 200.000 đồng từ iTel
                                        </li>
                                    </ul>
                                    <h4 style="color: red; font-weight: bold;">
                                        C. Các lưu ý khác:
                                    </h4>
                                    <ul style="list-style-type: none;">
                                        <li>
                                            1.	Đây là chương trình liên kết Mở thẻ tín dụng giữa VPBank và Itel.
                                        </li>
                                        <li>
                                            2.	Bạn sẽ được chuyển hướng sang màn hình mở thẻ tín dụng của VPBank.
                                        </li>
                                        <li>
                                            3.	VPBank (Ngân hàng TMCP Việt Nam Thịnh Vượng) cung cấp các sản phẩm/dịch vụ tài chính - ngân hàng giúp khách hàng và đối tác một cách minh bạch, thuận tiện, hiệu quả.
                                        </li>
                                        <li>
                                            4.	Đối với các vấn đề phát sinh khiếu nại của khách hàng, VPBank có trách nhiệm giải quyết. Trong quá trình mở và kích hoạt thẻ, nếu Bạn cần hỗ trợ, vui lòng liên hệ hotline VPBank: 1900545415 (1000đ/phút)
                                        </li>
                                    </ul>
                                    <p>
                                        Thông tin chi tiết về thể lệ chương trình xem <a href="/promotion-rule"
                                            class="red-link">TẠI ĐÂY</a>
                                    </p>
                                </div>
                                <div class="open-credit-form-container" v-if="step === 2">
                                    <p>
                                        Bạn vui lòng hoàn thiện các thông tin bên dưới và chọn "Mở thẻ ngay"
                                    </p>
                                    <validation-observer ref="infoValid">
                                        <div class="row m-0">
                                            <div class="col-md-12 p-0 mt-019">
                                                <validation-provider #default="{ errors }" rules="required">
                                                    <label class="d-block font-weight-bold">Họ tên <span
                                                            class="text-primary">*</span></label>
                                                    <b-form-input size="sm1" maxlength="35" class="donate-input"
                                                        ref="fullNameRef"
                                                        v-model="userInfo.fullName"
                                                        :state="errors.length > 0 ? false : null"
                                                        placeholder="Nhập đúng họ và tên đăng ký mở thẻ VPBank" />
                                                    <small class="fs-error-txt" v-if="errors.length > 0">
                                                        Vui lòng nhập đúng họ và tên đăng ký mở thẻ VPBank
                                                    </small>
                                                </validation-provider>
                                            </div>

                                            <div class="col-md-12 p-0 mt-019">
                                                <validation-provider #default="{ errors }" rules="required|min:10|VNPhone">
                                                    <label class="d-block font-weight-bold">Điện thoại liên lạc <span
                                                            class="text-primary">*</span></label>
                                                    <b-form-input type="text"
                                                        ref="contactPhoneRef"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                                        maxlength="10" size="sm1" class="donate-input"
                                                        v-model="userInfo.contactPhone"
                                                        :state="errors.length > 0 ? false : null"
                                                        placeholder="Nhập đúng số điện thoại đăng ký mở thẻ VPBank" />
                                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đúng
                                                        số điện thoại đăng ký mở thẻ VPBank</small>
                                                </validation-provider>
                                            </div>

                                            <div class="col-md-12 p-0 mt-019">
                                                <validation-provider #default="{ errors }" rules="required|email">
                                                    <label class="d-block font-weight-bold">E-mail <span
                                                            class="text-primary">*</span></label>
                                                    <b-form-input size="sm1" maxlength="40" class="donate-input"
                                                        ref="emailRef"
                                                        v-model="userInfo.email" :state="errors.length > 0 ? false : null"
                                                        placeholder="Vui lòng nhập Email của bạn" />
                                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập chính
                                                        xác email</small>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </validation-observer>
                                </div>
                                <button class="open-credit-form-btn-continue"
                                    @click="step === 1 ? step = 2 : onOpenCreaditCard()">
                                    {{ step == 1 ? "Tiếp tục" : "Mở thẻ ngay" }}
                                </button>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12 h-100 p-0 col-step-2" v-if="step === 2">
                            <b-card class="p-0 m-0 h-100" body-class="p-0">
                                <b-card-text>
                                    <div class="open-credit-form">
                                        <div class="open-credit-form-title">
                                            MỞ THẺ VPBANK NGAY RINH 200K VỀ TAY
                                        </div>
                                        <hr class="open-credit-form-hr" />
                                        <div class="open-credit-form-container">
                                            <p>
                                                Bạn vui lòng hoàn thiện các thông tin bên dưới và chọn "Mở thẻ ngay"
                                            </p>
                                            <validation-observer ref="infoValid">
                                                <div class="row m-0">
                                                    <div class="col-md-12 p-0 mt-019">
                                                        <validation-provider #default="{ errors }" rules="required">
                                                            <label class="d-block font-weight-bold">Họ tên <span
                                                                    class="text-primary">*</span></label>
                                                            <b-form-input size="sm1" maxlength="35" class="donate-input"
                                                                v-model="userInfo.fullName"
                                                                :state="errors.length > 0 ? false : null"
                                                                placeholder="Nhập đúng họ và tên đăng ký mở thẻ VPBank" />
                                                            <small class="fs-error-txt" v-if="errors.length > 0">
                                                                Vui lòng nhập đúng họ và tên đăng ký mở thẻ VPBank
                                                            </small>
                                                        </validation-provider>
                                                    </div>

                                                    <div class="col-md-12 p-0 mt-019">
                                                        <validation-provider #default="{ errors }"
                                                            rules="required|min:10|VNPhone">
                                                            <label class="d-block font-weight-bold">Điện thoại liên lạc
                                                                <span class="text-primary">*</span></label>
                                                            <b-form-input type="text"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                                                maxlength="10" size="sm1" class="donate-input"
                                                                v-model="userInfo.contactPhone"
                                                                :state="errors.length > 0 ? false : null"
                                                                placeholder="Nhập đúng số điện thoại đăng ký mở thẻ VPBank" />
                                                            <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng
                                                                nhập đúng số điện thoại đăng ký mở thẻ VPBank</small>
                                                        </validation-provider>
                                                    </div>

                                                    <div class="col-md-12 p-0 mt-019">
                                                        <validation-provider #default="{ errors }" rules="required|email">
                                                            <label class="d-block font-weight-bold">E-mail <span
                                                                    class="text-primary">*</span></label>
                                                            <b-form-input size="sm1" maxlength="40" class="donate-input"
                                                                v-model="userInfo.email"
                                                                :state="errors.length > 0 ? false : null"
                                                                placeholder="Vui lòng nhập Email của bạn" />
                                                            <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng
                                                                nhập chính xác email</small>
                                                        </validation-provider>
                                                    </div>
                                                </div>
                                            </validation-observer>
                                        </div>
                                        <button class="open-credit-form-btn-continue"
                                            @click="step === 1 ? step = 2 : onOpenCreaditCard()">
                                            {{ step == 1 ? "Tiếp tục" : "Mở thẻ ngay" }}
                                        </button>
                                    </div>
                                </b-card-text>
                            </b-card>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="error-info-modal" v-if="errorInfo.isError">
            <div class="error-modal-container">
                <div class="close-btn__error-modal">
                    <img alt="close" src="../../../assets/images/close-icon.png" @click="errorInfo.isError = false" />
                </div>
                <div class="main-icon__error-modal">
                    <img alt="close" src="../../../assets/images/X.png" />
                </div>
                <div class="title__error-modal">
                    Lỗi rồi!!!
                </div>
                <div class="content__error-modal">
                    {{ errorInfo.infoError }}
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <button class="b2c-search-button b2c-search-button-red mt-0 mb-4 retype-btn"
                        @click="onClickError">
                        Nhập lại
                    </button>
                </div>
            </div>
        </div>

        <div class="error-info-modal" v-if="isSuccess">
            <div class="error-modal-container">
                <div class="close-btn__error-modal">
                    <img alt="close" src="../../../assets/images/close-icon.png" @click="isSuccess = false" />
                </div>
                <div class="main-icon__error-modal">
                    <img alt="close" src="../../../assets/images/successCheck.png" />
                </div>
                <div class="title__error-modal">
                    Mở thẻ thành công!
                </div>
                <div class="content__error-modal">
                    {{ errorInfo.infoError }}
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <button class="b2c-search-button b2c-search-button-red mt-0 mb-4 retype-btn" @click="isSuccess = false">
                        Đóng
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BCard, BCardText, BCardHeader, BFormInput } from "bootstrap-vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";

extend("VNPhone", (value) => {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    return value.match(regexPhoneNumber) ? true : false;
});

export default {
    components: {
        BCard,
        BCardText,
        BCardHeader,
        ValidationProvider,
        ValidationObserver,
        BFormInput
    },
    data() {
        return {
            required,
            min,
            email,
            step: 1,
            userInfo: {
                fullName: "",
                contactPhone: "",
                email: "",
            },
            errorInfo: {
                isError: false,
                infoError: ''
            },
            isSuccess: false
        };
    },
    methods: {
        openVPBank() {
            const a = document.createElement('a')
            a.href = "https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb"
            a.target = "_blank"
            a.click()
        },
        async onOpenCreaditCard() {
            var validate = await this.$refs.infoValid.validate().then((success) => {
                return success;
            });

            await useJwt
                .post("saveInforCusVpBank", this.userInfo)
                .then((response) => {
                    if (response.data.code !== 200) {
                        this.errorInfo.isError = true
                        this.isSuccess = false
                        this.errorInfo.infoError = response.data.message
                    }
                    else {
                        const a = document.createElement('a')
                        a.href = "https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb"
                        a.click()
                    }
                });
        },
        onClickError() {
            this.errorInfo.isError = false
            if(this.errorInfo.infoError === 'Họ tên không được để trống') {
                this.$refs['fullNameRef'].focus()
            }
            else if(this.errorInfo.infoError === 'Điện thoại liên lạc không được để trống' || this.errorInfo.infoError ===  "Điện thoại liên lạc không đúng định dạng") {
                this.$refs['contactPhoneRef'].focus()
            }
            else {
                this.$refs['emailRef'].focus()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.b2c-pc-card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 120px;

    display: flex;
    align-items: center;
    column-gap: 22px;

    img {
        width: 9px;
        cursor: pointer;
    }

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 11px;
        color: #424242;
    }
}

.pb-sp-040 {
    padding-bottom: 0;
}

.mt-019 {
    margin-top: 19px;
}

.col-step-1 {
    display: block;
}

.col-step-2 {
    display: none;
}

.open-credit-form {
    padding: 32px 30px;
    background: #FFFFFF;
    border-radius: 0px 10px 10px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .open-credit-form-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #454545;
    }

    .open-credit-form-hr {
        margin-top: 13px;
        margin-bottom: 13px;
        width: 100%;
    }

    .open-credit-form-btn-continue {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 11px;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
        background: #ED1F24;
        border-radius: 6px;
        padding: 17.75px 0;
    }

    .open-credit-form-container {
        margin-bottom: 10px;
        height: 100%;

        // min-height: 300px;
        // max-height: 400px;
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #000000;
        }
    }

    .open-credit-privacy {
        margin-top: 19px;
        margin-bottom: 10px;
        height: 100%;
        min-height: 300px;
        max-height: 400px;
        overflow-y: auto;
        text-align: justify;

        p,
        ul li,
        h4,
        ol li {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #000000;
        }

        ul {
            list-style: disc;
            padding-inline-start: 15px;
        }

        ol {
            padding-inline-start: 15px;
            margin-block-start: 0;
        }

        .red-link {
            color: red;
        }
    }
}

.open-credit-apply-text {
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 85px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
}

.list-vpbank-info {
    margin-top: 47px;
    padding-left: 85px;
    padding-right: 16px;
    border-radius: 10px 0px 0px 10px;

    ul {
        list-style-image: url("../../../assets/images/open-credit-list-image.png");

        li {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #FFFFFF;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}

.open-credit-button {
    cursor: pointer;
}

.open-credit-title {
    position: relative;
    margin-top: 41px;

    img {
        max-width: 90%;
    }
}

.credit-card-container {
    background-image: url("../../../assets/images/open-credit-card-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.b2c-search-button {
    width: calc(100% - 50px);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    color: #424242;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 11px;
    height: 48px;
    background: #ffffff;
    -webkit-border-radius: 26px;
    border-radius: 26px;
    margin: 20px 31px;
}

.b2c-search-button-red {
    color: #ffffff !important;
    background: #ed1f24 !important;
}

.donate-input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}

@media screen and (max-width: 593px) {
    .credit-card-container {
        background-image: url("../../../assets/images/open-credit-card-bg.png");
        background-size: auto 100%;
        background-position: 50% 0;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 768px) {
    .b2c-pc-card-content {
        padding-top: 19px;
        padding: 0;
    }

    .b2c-pc-card-header {
        background: #FFFFFF;
        padding-left: 24px;

        span {
            font-size: 18px;
        }
    }

    .list-vpbank-info {
        padding-left: 45px;
    }

    .open-credit-form {
        border-radius: 0;
    }

    .open-credit-form {
        height: auto;
    }

    .pb-sp-040 {
        padding-bottom: 40px;
    }

    .col-step-1 {
        display: none;
    }

    .col-step-2 {
        display: block;
        padding: 0px 24px !important;

        .open-credit-form {
            height: fit-content;
            overflow-y: hidden;

            .open-credit-form-btn-continue {
                margin-top: 16px;
            }
        }

        .card {
            height: fit-content !important;
            background: transparent;
            box-shadow: none;
        }

        .card-body {
            position: relative;
            height: fit-content;
            top: -40px;
            border-radius: 1rem;

            .card-text {
                border-radius: 1rem;

                .open-credit-form {
                    border-radius: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 577px) {
    .text-container {
        padding: 0 87px;
    }

    .b2c-pc-card-content {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 576px) {
    .text-container {
        padding: 0 12px;
    }
}

.b2c-page-bg {
    background: rgba(242, 242, 242, 1);
}

.error-info-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #02020296;
    z-index: 99999;
}

.error-modal-container {
    width: 360px;
    min-height: 354px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.close-btn__error-modal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 12px;
}

.close-btn__error-modal>img {
    cursor: pointer;
    width: 19px;
    height: 19px;
}

.main-icon__error-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.main-icon__error-modal>img {
    width: 115px;
    height: 115px;
}

.title__error-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    margin-top: 24px;
    font-family: 'Roboto';
}

.content__error-modal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-inline: 20px;
    text-align: center;
    margin: 15px 0;
}

.retype-btn {
    width: fit-content;
    padding-inline: 35px;

}</style>